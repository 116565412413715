 @import "_variables";
 // .PanelButtonA {
 //     display: block;
 //     font-size: 100%;
 //     padding: .3% 1%;
 //     border-radius: 0.06667em;
 //     margin-bottom: 0.26667em px;
 //     font-weight: 300;
 //     border-width: 0.06667em;
 //     color: rgba(0, 0, 0, 0.5);
 //     background: rgba(255, 255, 255, 0.7);
 //     border-color: none;
 //     outline: none;
 //     float: left;
 //     margin-right: 0.2em;
 //   }
 //   .PanelButtonA:hover {
 //     background-color: rgba(255, 255, 255, 0.5);
 //   }
 //   .MenuPanel {
 //     position: absolute;
 //     left: 1%;
 //     top: 5%;
 //     display: none;
 //     background: rgba(255, 255, 255, 0.7);
 //     padding: 0.33333em 0.6em;
 //     color: rgba(0, 0, 0, 0.5);
 //     border-radius: 0.26667em;
 //     margin-bottom: 0.26667em px;
 //     font-weight: 300;
 //     border-width: 0.06667em;
 //     border-color: rgba(255, 255, 255, 0.5);
 //     float: left;
 //     margin-left: 0.2em;
 //     opacity: 1;
 //   }
 //   .MenuOption {
 //     display: block;
 //     float: none;
 //     clear: both;
 //     font-size: .61em;
 //     background: none;
 //     padding: 0.33333em 0.26667em;
 //     color: rgba(0, 0, 0, 0.5);
 //     border-radius: 0;
 //     margin-bottom: 0.26667em px;
 //     font-weight: 300;
 //     border-width: 0px;
 //     border-color: none;
 //     outline: none;
 //   }
 //   .MenuOption:hover {
 //     color: black;
 //   }
 //   .MenuOption:active {
 //     color: black;
 //     border: none;
 //     outline: none;
 //   }
 //   .MenuOption:focus {
 //     color: black;
 //     border: none;
 //     outline: none;
 //   }
 //   .ButtonGroup{
 //     position:absolute;
 //     width:90%;
 //     left:1%;
 //     top:1%;
 //     padding:0;
 //     // border:1px solid red;
 //   }
 @font-face {
     font-family: Biko;
     src: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/4273/biko-black.woff");
 }
 
 .PanelButtonA {
     display: inline;
     font-size: 100%;
     font-weight: normal;
     padding: .3% 1%;
     border-radius: 0.06667em;
     margin-bottom: 0.26667em px;
     border-width: 0.06667em;
     color: black;
     background: none;
     border-color: none;
     border: none;
     outline: none;
     float: left;
     margin-right: 0.4em;
 }
 
 .PanelButtonB {
     display: inline;
     font-size: 100%;
     font-weight: normal;
     padding-right: 3px;
     border-radius: 0.06667em;
     //margin-bottom: 0.26667em px;
     border-width: 0.06667em;
     color: black;
     background: none;
     border-color: none;
     border: none;
     outline: none;
     float: right;
     //margin-right: 0.4em;
 }
 
 .material-icons {
     vertical-align: middle;
 }
 
 .material-icons.menubuttons {
     font-size: 1.2em;
     vertical-align: middle;
 }
 
 *:focus {
     outline: none !important
 }
 
 .PanelButtonA:hover {
     /* background-color: rgba(255, 255, 255, 0.5); */
     background-color: none;
     color: rgb(85, 85, 85);
 }
 
 .MenuPanel {
     position: absolute;
     left: 1%;
     top: 5%;
     display: none;
     background: rgba(255, 255, 255, 0.7);
     padding: 0.33333em 0.6em;
     border-radius: 0.26667em;
     margin-bottom: 0.26667em px;
     font-weight: 300;
     border-width: 0.06667em;
     border-color: rgba(255, 255, 255, 0.5);
     float: left;
     margin-left: 0.2em;
     opacity: 1;
 }
 
 .MenuOption {
     display: block;
     float: none;
     clear: both;
     font-size: .8em;
     background: none;
     padding: 0.33333em 0.26667em;
     color: rgba(0, 0, 0, 0.9);
     border-radius: 0;
     margin-bottom: 0.26667em px;
     font-weight: 300;
     border-width: 0px;
     border-color: none;
     outline: none;
 }
 
 .MenuOption:hover {
     color: rgb(100, 100, 100);
 }
 
 .MenuOption:active {
     color: rgb(100, 100, 100);
     border: none;
     outline: none;
 }
 
 .MenuOption:focus {
     color: rgb(100, 100, 100);
     border: none;
     outline: none;
 }
 
 .ButtonGroup {
     position: absolute;
     width: 100%;
     left: 0px;
     top: 0px;
     padding: .5% 0 .5% .5%;
     background-color: rgba(255, 255, 255, 0.7);
     //background-image: url(./../svg/BiosimsMA_logo.svg);
     background-repeat: no-repeat;
     // background-attachment: fixed;
     background-position: right 5% top 50%;
     background-size: 10%;
 }
 
 .p3sdk-interactive-transcript-bottom-bar {
     display: none;
 }
 
 .captions {
     opacity: 1;
     left: 1%;
     transform: translate(0px, 0px);
     bottom: 32px;
     position: absolute;
     display: flex;
     justify-content: center;
     height: auto;
     color: white;
     user-select: none;
     z-index: 5000;
     .captionText {
         font-size: 2.5ex;
         cursor: pointer;
         user-select: none;
     }
     h6 {
         display: inline;
         span {
             background-color: rgba(51, 51, 51, 0.7);
         }
     }
     &.nomoves {
         transform: translateY(30px);
         transition: 0.3s ease;
     }
     &.centered {
         width: 98%;
         left: 1%;
     }
     &.inactive {
         display: none;
         pointer-events: none;
     }
 }